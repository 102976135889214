button.downloadButton {
    margin-left: 5%;
    background-color: #282c34;
    margin: 5%;
    color: #fff;
    border-radius: 5px;
  }

  button.downloadButton:hover {
    opacity: .5;
  }

p.prediction{
    margin-left: 5%;
}