
.row-info {
    height: 45vh;
}

.row-info:after {
    content: "";
    display: table;
    clear: both;
  }
  .columnContactus {
    float: left;
    width: 50.0%;
  }

  @media screen and (max-width: 750px) {
    .columnContactus {
      width: 100%;
    }
  }
p.contact {
    text-align: center;
    margin-top: 5%;
    color:#273746;
    }
p.contactDesc{
    text-align: center;
    margin-bottom: 7.5%;
    color:#273746;
}

h1.contact {
    text-align: center;
    color:#273746;
    }
h2.contact{
    text-align: center;
    color:#273746;
}

.imageContainerContact{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}
a.contactUsemail{
    text-decoration: none;
    color: #ff5733; 
}