
img.logo{
	width: 100%;
	margin-top:20.%;
}

.rowLogo{
}

a.affiliatedInstituion{
  text-decoration: none;
  color:#273746;
}
a.affiliatedInstituion:hover{
  opacity: 50%;
}

.outer{
    width:100%;
    display:block;
    text-align:center;
    position:relative;
    overflow: hidden;
    height:750px;
  }

.home-top-video{
    left:0%;
    top:0%;
    height:100%;
    width:100%;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
.home-text{
    left:0%;
    top:0;
    height:100%;
    width:100%;
    padding-top:20%;
    overflow: hidden;
    position: absolute;
    z-index: 0;
    letter-spacing: 10px;
    font-size: 30px;
    line-height: 10px;
    color:#273746;
}
h1.home-text{
  color: #ececec;
}
h3.home-text{
  color: #ececec;
  line-height: 200%; 
}
.headerText{
  visibility: hidden;
  left:0%;
  top:0;
  height:100%;
  width:100%;
  padding-top:20%;
  z-index: 0;
  letter-spacing: 10px;
  font-size: 30px;
  color:#273746;
}
h1.Header{
  font-size: 45px;
}

@media screen and (max-width: 800px) {
  .home-top-video {
    visibility: hidden;
  }
  .home-text{
    visibility: hidden;
  }
  .headerText{
    visibility: visible;
  }
}

.columnLogo{
	float: left; 
	width: 20%;
	padding: 2.5%;
}
.header {
    margin-top: 6%;
    margin-bottom: 2%;
    padding: 1%;
    background: white;
    font-family: 'Karla', sans-serif;
  }
  
.header h1 {
    text-align: left;
    font-size: 50px;
    font-family: 'Karla', sans-serif;
}
h1.about{
    margin-bottom: 0%;
    margin-left: 7%;
    margin-right: 15%;
    letter-spacing: -2px;
    font-size: 60px;
    color: #273746;
}
p.subheading {
    margin-top: 0%;
    margin-bottom: 1%;
    margin-left: 7.5%;
    margin-right: 5%;
    font-size: 22px;
    letter-spacing: 3px;
    float: justify;
    font-style: italic;
}
p.three {
    margin-top: 10px;
    border-top: 2px;
    margin-bottom: 5px;
    margin-left: 7.5%;
    margin-right: 7.5%;
    font-size: 22px;
    letter-spacing: 1px;
    line-height: 1.5;
    text-align: justify;
    color: #273746;
}
  

p.about-Plasticanalytics{
    margin-left: 15%; 
    margin-right: 10%;
    font-size: 25px; 
    text-align: center;
}

p.about-PlasticanalyticsTwo{
    margin-left: 15%; 
    margin-right: 10%; 
    font-size: 25px; 
    text-align: center;
}

p.about-PlasticanalyticsThree{
    margin-left: 10%; 
    margin-right: 10%;
    text-align: justify; 
    font-size: 22px;
}

main.background-grey{
    justify-content: center;
    align-items: center;
    margin-top:7.5%;
  }
  section.background-grey {
    background-color: #ffffff;
    width: 100%;
    margin-top:20%;
    margin-bottom:  2.5%;
    border-radius: 1%;
  }
  .card {
    background-color: white;
    margin:'auto';
    position: justify;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    max-width: 80%;
    width: 75%;
    text-align: justify;
    margin: auto;
    border-radius: 1.5%; 
  }
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  .card-horizontal {
    box-shadow: 0 1px 16px 0 rgba(0,0,0,0.2);
    display: flex;
    flex: 1 1 auto;
    border-radius: .8rem;
  }

  @media screen and (max-width: 800px) {
    .card-about {
      max-width: 80rem;
    }
    .img-square-wrapper{
      max-width: 25rem;
      height: auto;
    }
  }
  @media screen and (max-width: 750px) {
    .card-about {
      flex-direction: column;
    }
    .card-body {
      padding: .5rem;
      min-width: 90%;
      max-width: 90%;
      flex-direction: column;
      margin: 1rem;
    }
    .card-horizontal{
      flex-direction: column;
      max-width: 100%;
      margin: auto;
      width: 100%;
      font-size: inherit;
    }
    .img-square-wrapper{
      width: 100%;
      height: auto;
    }
  }
  hr.aboutpage{
    background-color:#ff5733;
    color: #ff5733;
    width: 50%;
    height: 2.5px;
    margin: auto;
  }
  .img-square-wrapper{
    width: auto;
    min-width: 25%;
    max-width: 25rem;
  }
  
  .img-square-wrapper img{
    border-radius: .6rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  

  .no-bullets {
    list-style-type: none;
  }
  h1.our_team{
    margin-bottom: 0%;
    margin: auto;
    text-align: center;
    letter-spacing: -2px;
    font-size: 60px;
    color: #273746;
  }
  h2.our_team{
    margin-bottom: 0%;
    color: #273746;
    margin: auto;
    text-align: center;
    letter-spacing: -2px;
    font-size: 32px;
  }
  h3.our_team_sub{
    margin-bottom: 0%;
    color: #273746;
    margin: auto;
    text-align: center;
    letter-spacing: -2px;
    font-size: 18px;
    opacity: 0.5;
  }
  h3.our_team{
    margin-bottom: 0%;
    color: #273746;
    margin: auto;
    text-align: center;
    letter-spacing: -2px;
    font-size: 22px;
    opacity: 0.5;
  }
  a.our_team{
    margin-top: 1%;
    font-size: 22px;
    color: #ff5733;
    margin: auto;
  }
  p.our_team{
    margin-bottom: 0%;
    color: #273746;
    margin-right: 10%;
    margin-left: 10%;
    text-align: justify;
    font-size: 18px;
  }
  td.icon_center{
    text-align: center;
  }
  
  h1.sub_head{
    letter-spacing: 6px;
    font-size: 40px;
    position: right;
    margin-bottom: 5px;
    margin-left: 60px;
    margin-right: 200px;
    text-align:left;
    list-style-type: none; 
  }
  h1.sub_left_head{
    letter-spacing: 6px;
    font-size: 40px;
    position: right;
    margin-bottom: 5px;
    margin-left:60px;
    margin-right: 200px;
    text-align:left;
    list-style-type: none; 
  }
  a.aboutpage {
    color: #ffffff; 
    text-decoration: none;
    background-color: #273746;
    border-radius: 15%;
   } 
  h1.card-title{
    margin-bottom: 0.5rem;
    font-size: 30px;
    margin-top: 0rem;
    margin-left: -5%;
    text-align: center;
    color: #273746;
  }
  a.aboutpage2 {
    color: #273746; 
    text-decoration: none;
    margin: auto;
} 
img.image_hyperlinks{
    width: 100%;
    padding: 5%;
    border-radius: 20%;
    padding-bottom: 5%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 800px) {
    img.image_hyperlinks{
      width: 90%;
      padding-top: 5%;
  }
}
  .row_our_team:after {
    content: "";
    display: table;
    clear: both;
  }
  .column_our_team {
    float: left;
    width: 33.33%;
  }
  @media screen and (max-width: 750px) {
    .column_our_team {
      width: 100%;
    }
  }
  img.our_team_responsive{
    height: auto;
    padding: 5%;
    border-radius: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  img.logoIcon{
      height: 16px;
      width: 16px;
      padding-left: -15%;
  }

  .centreIccon{
    text-align:center
  }