.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1,h2,h3,h4 p {
  color: #282c34;
}

div.columnLeft{
  flex: 1; 
  background-color: #273746;
  border-radius: 10px;
  background: rgb(39,55,70);
  background: linear-gradient(310deg, rgba(39,55,70,1) 0%, rgba(62,78,94,1) 53%, rgba(86,102,118,1) 100%);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.171);
  order: 1; 
}
div.columnRight{
  flex: 1 1 0%;    
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.171);
  border-radius: 5px;
  order: 2; 
}
div.entireView{
  display: flex;
  width: 80%; 
  padding-left: 10%;
  padding-right: 10%
}

@media (max-width: 1000px) {
  div.entireView{
    display: grid;
    flex:none;
    order: 0; 
  }
  div.columnLeft, div.columnRight {
    flex: none; /* Disable flex to make them stack vertically */
    width: 100%; 
    margin-bottom: 10%;
  }
  div.columnRight{
    height: 350px;
  }
}