/* Navigation.css */
.navigation {
    background-color: #fff;
    padding: 10px 0;
    text-align: center;
    margin-bottom: 5px;
  }
  
  .nav-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-item {
    display: inline-block;
    margin-right: 20px;
  }
  
  .nav-item a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
  }
  .nav-item a.active {
    background-color: #ff5733;
    border-radius: 5px;
    box-shadow: 2px 5px 5px 0px #282c34;
    padding: 5px;
    color: white;
  }  
  