.dropdown-container {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .dropdown-list li {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .dropdown-list li:hover {
    background-color: #f5f5f5;
  }
  
  .dropdown-list li:focus {
    outline: none;
    background-color: #f5f5f5;
  }
  