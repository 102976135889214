footer.footer {
    text-align: center;
    background-color: #ffffff;
    color: #273746;
    word-spacing: 40%;
    padding: 1%;
}
a.footer {
  text-align: center;
  background-color: #ffffff;
  color: #273746;
  word-spacing: 5%;
  padding: 1%;
}
p.footer{
	display: inline-block;
	font-size: 20px;
  }
img.footerIcon{
	height: 15px;
	width: 15px;
}
h4.page_end {
  text-align: center;
  font-size: medium;
  color: #273746;
}
a.nav-email-2, a.navEmail{
  color:#273746;
}

