
.countDownouter {
    display: table;
    top: 0;
    left: 0;
    height: 550px;
    width: 100%;
  }
  
  .countDownmiddle {
    display: table-cell;
    vertical-align: middle;
  }
  
  .countDowninner {
    margin-left: auto;
    margin-right: auto;
  }

.countDowncontainer {
    color: #333;
    margin: 0 auto;
    text-align: center;
  }
   
   
  li.countDown {
    display: inline-block;
    font-size: 1.5em;
    list-style-type: none;
    padding: 1em;
    text-transform: uppercase;
    
  }
   
  li.countDown span.countDown {
    display: block;
    font-size: 4.5rem;
  }
   
  .message {
    font-size: 4rem;
  }
   
  #content {
    display: none;
    padding: 1rem;
  }
   
  @media all and (max-width: 768px) {
    h1.countDown {
      font-size: 1.5rem;
    }
   
    li.countDown {
      font-size: 1.125rem;
      padding: 0.75rem;
    }
   
    li.countDown span.countDown {
      font-size: 3.375rem;
    }
  }
   