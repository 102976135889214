/*Educo*/
/*Login page*/
.outer {
    height: 50%;
    width: 100%;
    left: 0;
    margin-top: 5%;
  }
  
  form.login {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
  }
  
  input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
  }
  
  .login-container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    margin: auto;
    width: 90%;
    top: 50%;
    min-height: 480px;
    vertical-align: middle;
    min-width: 250px;
    max-width: 550px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 800px) {
  .login-container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    margin: auto;
    width: 100%;
    top: 50%;
    min-height: 480px;
    vertical-align: middle;
    min-width: 250px;
    max-width: 550px;
  }
  }
  
  
  .form-container {
    position: absolute;
    top: 0;
    height: 100%;
  }
  
  .log-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
  }
  
  .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
  }
  
  .overlay {
    background: #273746;
    background: -webkit-linear-gradient(to right, #273746, #273746);
    background: linear-gradient(to right, #273746, #273746);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
  }
  
  .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
  }
  
  .overlay-right {
    right: 0;
  }
  
  .social-container {
    margin: 10px 0;
  }
  
  .social-container a {
    border: 1px solid #dddddd;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
  }
  
  btn.login-social {
    color: solid #dddddd;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
    cursor: pointer;
  }
  
  btn.login-button {
    background-color: #273746;
    border-radius: 5%;
    color: #fdfefe;
    border: none;
    font-size: 18px;
    padding: 10px;
    margin: 5px;
    transition: 0.3s;
    cursor: pointer;
  }
  
  btn.login-button:hover {
    background-color: #ff5733;
  }
  
  img.img-login {
    height: 40px;
    width: 40px;
    cursor: pointer;
  }
  
  h1.login {
    text-align: center;
    color: #fff;
  }
  
  p.login {
    margin: 20px 0 30px;
  }
  
  h2.topic_header {
    text-align: center;
  }

  a.nav_email{
    text-decoration: none;
    color: #fff;
    padding-bottom: 10px;
  }

  a.forgotPassword{
    text-decoration: none;
    color: #fdfefe;
  }