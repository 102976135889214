label.inputSpectralLibrary {
    margin-left: 5%;
}

button.spectralButton {
    margin-left: 5%;
    background-color: #ff5733;
    width: 75px;
    height: 75px;
    color: #fff;
    border-radius: 5px;
}
button.spectralButton:hover{
    opacity: .5;
    color: #fff;
}

p.spectralOutputs{
    color: #fff;
    margin-left: 5%;
    font-weight: bold;
}

h2,h3,p.spectralPlotter{
    margin-left: 5%;
    color: #fff;
}

input.uploadInput{
    margin-left: 5%;
}